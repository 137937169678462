<template>
    <div class="">
      <Card class="p-m-2">
        <template #title> Ürün Bilgi Girişleri </template>
        <template #content>
          <TabMenu :model="items" v-model:activeIndex="aktifTab" />
          <router-view />
        </template>
      </Card>
    </div>
  </template>
  
  <script>
  import { useVuelidate } from "@vuelidate/core";
  import GlobalServis from "../../../../services/GlobalServis";
  
  export default {
    setup: () => ({ v$: useVuelidate() }),
    data() {
      return {
        aktifTab: 0,
        items: [
        {
            label: "Genel Bakış",
            icon: "pi pi-fw pi-eye",
            to: "/Yonetim/UrunGiris/UrunGenelBakis",
          },
          {
            label: "Tür",
            icon: "pi pi-fw pi-home",
            to: "/Yonetim/UrunGiris/Tur",
          },
          {
            label: "Marka",
            icon: "pi pi-fw pi-calendar",
            to: "/Yonetim/UrunGiris/Marka",
          },
          {
            label: "Model",
            icon: "pi pi-fw pi-pencil",
            to: "/Yonetim/UrunGiris/Model",
          },
        ],
      };
    },
    methods: {},
    mounted() {},
    components: {},
  };
  </script>
  
  <style lang="scss" scoped></style>
  